import styled from '@emotion/styled';

import { SPACING, MEDIA_QUERY, COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import { SCREEN_MD, SCREEN_LG, SCREEN_XL } from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import {
  Z_INDEX_MAP,
  Z_INDEX_TEXT_LAYER_KEY,
} from 'components/publicWebsiteV2/util/zIndexConstants';

export const SpPhotoContainer = styled.div<{ inPreview?: boolean }>`
  height: 240px;
  width: 240px;
  margin-bottom: ${SPACING.MD};

  @media (min-width: ${SCREEN_MD}px) and (max-width: ${SCREEN_LG - 1}px) {
    height: 150px;
    width: 150px;
  }

  @media (min-width: ${SCREEN_LG}px) and (max-width: ${SCREEN_XL - 1}px) {
    height: 180px;
    width: 180px;
  }

  ${props =>
    props.inPreview
      ? `
      height: 150px !important;
      width: 150px  !important;
  `
      : ''}
`;

export const SpPhoto = styled.img`
  border-radius: 20px;
  border: 1px solid ${COLORS3.BLACK_015};
  height: inherit;
  width: inherit;
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};

  &:hover {
    cursor: pointer;
  }
`;

export const SpPhotosContainer = styled.div<{ inPreview?: 'DESKTOP' | 'MOBILE' }>`
  align-items: center;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: ${SPACING.XL} auto 0 auto;
  max-width: 500px;
  width: 100%;

  ${MEDIA_QUERY.MOBILE} {
    flex-flow: column;
  }
  ${({ inPreview }) => inPreview === 'MOBILE' && 'flex-flow: column;'}

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const ViewAllContainer = styled.div`
  margin-top: 100px;
  text-align: center;

  button {
    max-width: 300px;
    width: 100%;
  }
`;

export const FlyoutPhotosContainer = styled.div<{ inPreview?: boolean }>`
  height: 100%;
  width: 100%;
  padding: 0 ${SPACING.XL} ${SPACING.XL} ${SPACING.XL};
  ${({ inPreview }) => (inPreview ? `> * {pointer-events: none;}` : '')}
`;
