import styled from '@emotion/styled';

import { SPACING, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

import {
  Z_INDEX_MAP,
  Z_INDEX_TEXT_LAYER_KEY,
} from 'components/publicWebsiteV2/util/zIndexConstants';

export const PhotosContainer = styled.div`
  display: grid;
  grid-gap: ${SPACING.MD};
  margin-top: ${SPACING.XL};
  text-align: center;
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const PhotoContainer = styled.div<{ hasCaption: boolean }>`
  ${MEDIA_QUERY.MOBILE} {
    ${({ hasCaption }) => hasCaption && `padding-bottom: ${SPACING.S32};`}
  }
`;

export const Photo = styled.img`
  width: 100%;
  border-radius: 20px;
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};

  &:hover {
    cursor: pointer;
  }
`;

export const PhotoRow = styled.div<{ useMobileView: boolean }>`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;
  ${({ useMobileView }) =>
    !useMobileView &&
    `
    ${MEDIA_QUERY.NOT_MOBILE} {
      grid-gap: ${SPACING.MD};
      grid-template-columns: 1fr 1fr 1fr 1fr;
      &:nth-of-type(5n + 1) {
        ${PhotoContainer} {
          &:first-of-type {
            grid-area: 1/1/4/4;
          }
        }
      }
      &:nth-of-type(5n + 2) {
        ${PhotoContainer} {
          &:last-of-type {
            grid-area: 1/3/3/5;
          }
        }
      }
      &:nth-of-type(5n + 4) {
        ${PhotoContainer} {
          &:last-of-type {
            grid-area: 1/2/4/5;
          }
        }
      }
      &:nth-of-type(5n + 5) {
        ${PhotoContainer} {
          &:last-of-type {
            grid-area: 1/1/3/3;
          }
        }
      }
    }
  `}
`;

export const MobileCaption = styled.div`
  display: none;
  ${MEDIA_QUERY.MOBILE} {
    display: block;

    &:empty {
      display: none;
    }
  }
`;
