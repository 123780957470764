import React, { useContext } from 'react';

import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';

// types
import type { WCmsPhotoView } from '@zola/svc-web-api-ts-client';

// utils
import chunkArray from 'components/publicWebsiteV2/pages/Gallery/chunkGalleryArray';
import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';

// styles
import { useFlyoutPanelContext } from 'components/publicWebsiteV2/contexts/flyoutPanelContext';
import {
  SpPhotosContainer,
  SpPhotoContainer,
  SpPhoto,
  ViewAllContainer,
  FlyoutPhotosContainer,
} from './SinglePageGalleryPhotos.styles';
import GalleryPhotos from '../GalleryPhotos';

export type SinglePageGalleryPhotosProps = {
  handleOpenLightbox: (photo: WCmsPhotoView) => void;
  photos: WCmsPhotoView[];
  urlParams: string;
};

const SinglePageGalleryPhotos: React.FC<SinglePageGalleryPhotosProps> = ({
  handleOpenLightbox,
  photos,
  urlParams,
}) => {
  const { device } = useContext(DeviceContext);
  const {
    actions: { showFlyoutPanel },
  } = useFlyoutPanelContext();
  const {
    state: {
      components: { ThemedButton },
      inPreview,
      enableInPreviewInteractions,
    },
  } = useWebsiteThemeContext();

  const spPhotos: WCmsPhotoView[] = photos.length > 8 ? photos.slice(0, 8) : photos;
  const showViewAll = photos && photos.length > 8;
  const canClickViewAll =
    !inPreview || (inPreview === 'DESKTOP' && enableInPreviewInteractions?.photo);

  const openGalleryFlyout = () => {
    if (canClickViewAll) {
      showFlyoutPanel({
        content: (
          <FlyoutPhotosContainer
            data-testid="flyout-photos-grid"
            inPreview={inPreview === 'DESKTOP'}
          >
            <GalleryPhotos
              handleOpenLightbox={handleOpenLightbox}
              identifierPrefix="flyout-photos"
              photos={photos}
              urlParams={urlParams}
            />
          </FlyoutPhotosContainer>
        ),
      });
    }
  };

  return (
    <>
      <SpPhotosContainer data-testid="sp-photos-list" inPreview={inPreview}>
        {!!spPhotos?.length &&
          ((device?.isDesktop() && !inPreview) || inPreview === 'DESKTOP'
            ? chunkArray(spPhotos)
            : [spPhotos]
          ).map(photoChunk =>
            photoChunk.map((photo: WCmsPhotoView) => (
              <PageAnimationWrapper key={`sp-photo--${photo.id}`}>
                <SpPhotoContainer inPreview={inPreview === 'DESKTOP'}>
                  <SpPhoto
                    src={
                      urlParams
                        ? `${getZolaImageFromUuid(photo.image_id as string)}?${urlParams}`
                        : `${getZolaImageFromUuid(photo.image_id as string)}`
                    }
                    onClick={() => handleOpenLightbox(photo)}
                    alt={photo.caption}
                    data-testid="sp-gallery-photo"
                    loading={!inPreview ? 'lazy' : undefined}
                  />
                </SpPhotoContainer>
              </PageAnimationWrapper>
            ))
          )}
      </SpPhotosContainer>
      {showViewAll && (
        <ViewAllContainer
          className={
            inPreview === 'DESKTOP' && enableInPreviewInteractions?.photo
              ? 'IcEnableContainerAndCursor'
              : ''
          }
        >
          <ThemedButton onClick={openGalleryFlyout} data-testid="sp-photos-view-all">
            View all photos
          </ThemedButton>
        </ViewAllContainer>
      )}
    </>
  );
};

export default SinglePageGalleryPhotos;
