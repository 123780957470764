import React, { useContext } from 'react';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';

// types
import type { WCmsPhotoView } from '@zola/svc-web-api-ts-client';

// utils
import chunkArray from 'components/publicWebsiteV2/pages/Gallery/chunkGalleryArray';
import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';

// styles
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import {
  MobileCaption,
  Photo,
  PhotoContainer,
  PhotoRow,
  PhotosContainer,
} from './GalleryPhotos.styles';

export type GalleryPhotosProps = {
  handleOpenLightbox: (photo: WCmsPhotoView) => void;
  identifierPrefix: string;
  photos: WCmsPhotoView[];
  urlParams: string;
};

const GalleryPhotos: React.FC<GalleryPhotosProps> = ({
  handleOpenLightbox,
  identifierPrefix,
  photos,
  urlParams,
}) => {
  const { device } = useContext(DeviceContext);

  const {
    state: {
      components: { styleCmsEntityBodyFont },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const StyledMobileCaption = styleCmsEntityBodyFont(MobileCaption);

  return (
    <PhotosContainer>
      {!!photos?.length &&
        ((device?.isDesktop() && !inPreview) || inPreview === 'DESKTOP'
          ? chunkArray(photos)
          : [photos]
        ).map((photoChunk, idx) => (
          <PhotoRow
            key={`${identifierPrefix}-row-${idx}`}
            useMobileView={!device?.isDesktop() || inPreview === 'MOBILE'}
          >
            {photoChunk.map((photo: WCmsPhotoView) => (
              <PhotoContainer key={`${identifierPrefix}--${photo.id}`} hasCaption={!!photo.caption}>
                <PageAnimationWrapper
                  animationProps={{ speed: 1.5 }}
                  key={`${identifierPrefix}--${photo.id}`}
                >
                  <Photo
                    src={
                      urlParams
                        ? `${getZolaImageFromUuid(photo.image_id as string)}?${urlParams}`
                        : `${getZolaImageFromUuid(photo.image_id as string)}`
                    }
                    onClick={() => handleOpenLightbox(photo)}
                    alt={photo.caption}
                    data-testid={`${identifierPrefix}-gallery-photo`}
                    loading={!inPreview ? 'lazy' : undefined}
                  />
                </PageAnimationWrapper>

                <StyledMobileCaption>{photo.caption}</StyledMobileCaption>
              </PhotoContainer>
            ))}
          </PhotoRow>
        ))}
    </PhotosContainer>
  );
};

export default GalleryPhotos;
